import React from "react"
import Layout from "../components/layout";
import {graphql} from "gatsby";
import Seo from "../components/seo";

const BiographyPage = ({data: { biografie } }) => {
    const parts = biografie.content.split('<!--nextpage-->');

    return (
        <Layout>
            <Seo title={"Biografie"} description={"Zanger-gitarist Jan de Bruijn (Rijsbergen, 1958) speelt al meer dan vijfendertig jaar uit het hoofd en uit het hart. Recht uit het hart."} />
            <section className={"w-full pt-10 flex flex-col items-center space-y-6 mb-10"}>
                <div className={"jdb--menu-spacer"} />

                <div className={"w-2/3"}>
                    <div className={"text-center text-red-400 mb-2"}>Rijsbergen, 1958</div>
                    <div className={"text-black text-3xl md:text-4xl text-tertiary font-primary text-center"}>Zanger-gitarist Jan de Bruijn (Rijsbergen, 1958) speelt al meer dan vijfendertig jaar uit het hoofd en uit het hart. Recht uit het hart.</div>
                    <div className={"text-white w-1/3 text-center"}></div>
                </div>
            </section>
            <section className={"w-full flex flex-col items-center min-h-screen bg-jan-6 bg-cover bg-fixed bg-bottom mb-10"} />
            <section className={"w-full flex flex-col items-center c-biography--content"}>
                <div className={`pl-10 pr-10 md:pl-0 md:pr-0 w-full md:w-2/3 text-2xl text-tertiary mb-10`} dangerouslySetInnerHTML={{__html: parts[0]}} />
                <div className={`w-full md:w-5/6 self-start bg-jan-8 min-h-[500px] bg-top bg-cover mb-10`} />
                <div className={`pl-10 pr-10 md:pl-0 md:pr-0 w-full md:w-2/3 text-2xl text-tertiary mb-10`} dangerouslySetInnerHTML={{__html: parts[1]}} />
                <div className={`w-full md:w-5/6 self-end bg-jan-5  min-h-[500px] bg-top bg-cover mb-10`} />
                <div className={`pl-10 pr-10 md:pl-0 md:pr-0 w-full md:w-2/3 text-2xl text-tertiary mb-10`} dangerouslySetInnerHTML={{__html: parts[2]}} />
            </section>
        </Layout>
    )
};

export default BiographyPage;


export const pageQuery = graphql`
query BiographyQuery {
    biografie: wpPage(slug: {eq: "biografie"}) {
        content
    }
}
`
